<template>
  <b-row>
    <b-col class="nopadding" cols="12" xl="4">
      <b-card>
        <div class="text-center">
          <img v-if="client.logo" :src="computed_logo(client.logo)"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <h6>{{$t('common.choose_another_image')}}</h6>
          <input type="file" class="text-center center-block file-upload"
          @change="getImage" id="fileControl">
        </div>
        <br>
        <div class="row h4"><i class="fa fa-user mr-1" aria-hidden="true"></i> {{$t('client.information')}}</div>
        <div class="row">
          <div class="col bg-secondary">
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label for="txt_name">{{$t('common.fullname')}}</label>
                  <div v-if="$v.client.name.$error" class="pull-right">
                      <div class="text-danger" v-if="!$v.client.name.required">{{$t('common.fullname_required')}}</div>
                  </div>
                  <b-form-input type="text" id="txt_name" :placeholder="$t('common.placeholder_fullname')"
                  v-model.trim="client.name" maxlength="50" ref="txt_name"
                  v-on:input="$v.client.name.$touch" size="sm">
                  </b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label for="ddlGender">{{$t('common.gender')}}</label>
                  <div v-if="$v.client.gender.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.client.gender.required">{{$t('common.gender_required')}}</div>
                  </div>
                  <b-form-select id="ddlGender" v-model.trim="client.gender" size="sm">
                      <option value="null" disabled>{{$t('common.select_gender')}}</option>
                      <option v-for="d in ls_gender" :value="d.id"
                          v-bind:key="d.id">{{d.name[$i18n.locale]}}</option>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group>
                  <label for="validation_dob">{{$t('common.dob')}}</label>
                  <date-picker v-model.trim="client.dob" :config="options1" class="date-picker-height"
                  :placeholder="$t('common.placeholder_dob')" id=validation_dob></date-picker>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label for="txt_client_pawn_phone">{{$t('common.phone')}}</label>
                  <div v-if="$v.client.phone.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.client.phone.required">{{$t('common.phone_required')}}</div>
                    <div class="text-danger" v-if="!$v.client.phone.numeric">{{$t('common.phone_number_only')}}</div>
                    <div class="text-danger" v-else-if="!$v.client.phone.minLength">{{$t('common.phone_ten_number')}}</div>
                  </div>
                  <b-form-input type="text" id="txt_client_pawn_phone"
                  v-model.trim="client.phone" maxlength="20"
                  v-on:input="$v.client.phone.$touch" size="sm"
                  :placeholder="$t('common.placeholder_phone')"></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group>
                  <label for="txtEmail">{{$t('common.email')}}</label>
                  <div v-if="$v.client.email.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.client.email.email">{{$t('common.email_required')}}</div>
                  </div>
                  <b-form-input type="email" id="txtEmail"
                  v-model.trim="client.email" maxlength="50"
                  v-on:input="$v.client.email.$touch" size="sm"
                  :placeholder="$t('common.placeholder_email')"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row" v-if="business=='pawn'||business=='hotel'||business=='hostel'">
              <div class="col-md-3">
                <b-form-group>
                  <label for="txt_cmnd">{{$t('common.cmnd')}}</label>
                  <div v-if="$v.client.cmnd.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.client.cmnd.required">{{$t('common.cmnd_required')}}</div>
                    <div class="text-danger" v-else-if="!$v.client.cmnd.isUnique">{{$t('common.cmnd_existing')}}</div>
                  </div>
                  <b-form-input type="text" id="txt_cmnd"
                  v-model.trim="client.cmnd" maxlength="15"
                  v-on:input="$v.client.cmnd.$touch" size="sm"
                  :placeholder="$t('common.placeholder_cmnd')"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group>
                  <label for="validation_issued_date">{{$t('common.issued_date')}}</label>
                  <div v-if="$v.client.issued_date.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.client.issued_date.required">{{$t('common.issued_date_required')}}</div>
                  </div>
                  <date-picker v-model.trim="client.issued_date" :config="options1" class="date-picker-height"
                    :placeholder="$t('common.placeholder_issued_date')" id=validation_issued_date></date-picker>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group>
                  <label for="txt_issued_place">{{$t('common.issued_place')}}</label>
                  <div v-if="$v.client.issued_place.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.client.issued_place.required">{{$t('common.issued_place_required')}}</div>
                  </div>
                  <b-form-input type="text" id="txt_issued_place" size="sm"
                  v-model.trim="client.issued_place" maxlength="100"
                  :placeholder="$t('common.placeholder_issued_place')"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label for="txtAddress">{{$t('common.address')}}</label>
                  <b-form-textarea type="text" id="txtAddress"
                  v-model.trim="client.address" maxlength="200"
                  :placeholder="$t('common.placeholder_address')">
                  </b-form-textarea>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="row pull-right mt-2">
          <b-button variant="outline-info" class="mr-1" size="sm"
          @click.prevent="clear()"><i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</b-button>
          <b-button variant="outline-success" @click.prevent="saveClient()" size="sm">
            <span class="icon is-small">
              <i class="fa fa-check"></i> {{$t('common.button.save')}}</span>
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col class="nopadding" cols="12" xl="8">
      <transition name="slide">
      <b-card>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" @keyup.enter="apply_filter_client">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter_client"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page_client"
        :per-page="per_page_client" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="name" slot-scope="data">
            <label>{{data.item.name}}</label>
          </template>
          <template slot="gender" slot-scope="data">
            <label>{{get_gender(data.item.gender)}}</label>
          </template>
          <template slot="company" slot-scope="data">
            <div v-if="data.item.company">{{data.item.company.name}}</div>
            <div v-else></div>
          </template>
          <template slot="branch" slot-scope="data">
            <div v-if="data.item.branch">{{data.item.branch.name}}</div>
            <div v-else></div>
          </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="phone" slot-scope="data">
            <b-badge>{{data.item.phone}}</b-badge>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge>
          </template>
          <template slot="bill" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="primary" @click="bill_pdf(data.item.id,80)" v-b-modal.viewModal><i
                  class="fa fa-file-pdf-o"></i> Bill 80</b-button>
              <b-button variant="primary" @click="bill_pdf(data.item.id,5)" v-b-modal.viewModal><i
                  class="fa fa-file-pdf-o"></i> Bill A5</b-button>
              <b-button variant="primary" @click="bill_pdf(data.item.id,4)" v-b-modal.viewModal><i
                  class="fa fa-file-pdf-o"></i> Bill A4</b-button>
            </b-button-group>
          </template>

          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)" v-b-tooltip.hover.left :title="$t('common.button.delete')">
                <i class="fa fa-trash"></i>
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)" v-b-tooltip.hover.left :title="$t('common.button.recover')">
                <i class="fa fa-recycle"></i>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count_client"
                        :page="page_client"
                        @change="change_page_client">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
      </b-card>
      </transition>
    </b-col>
    <b-modal id="viewModal" title="In bill" size="xl" header-bg-variant="info" no-body>
      <div id="div_oto_pdf" style="height:500px;"></div>
    </b-modal>
  </b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
  .button-box {
    text-align:center;
    margin-top:20px;
  }
  .div-scroll {
    max-height:500px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  fieldset.scheduler-border {
    border: 1px groove #e4e7ea !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }

  legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width:auto;
    padding:0 10px;
    border-bottom:none;
  }
</style>
<script>
import { validationMixin } from 'vuelidate'
import { required,requiredIf, minLength,maxLength,numeric,email }
from 'vuelidate/lib/validators'
import moment from 'moment'
import Pager from '../Pager'
import { mapGetters, mapActions } from 'vuex'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import {Role} from '../../helpers/role'
import { Business } from '../../helpers/business'

export default {
  name: 'Clients',
  mixins: [validationMixin],
  components: {Pager},
  props: {
    hover: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      processing:false,
      ls_gender: [],
      client:{
        id:null,
        logo:null,
        logo_url:null,
        name:null,
        code:moment().valueOf().toString(),
        email:null,
        dob:null,
        des:null,
        gender:null,
        phone:null,
        address:null,
        company_id:null,
        branch_id:null,
        user_create_id:null,
        user_update_id:null,
        business:null,
        issued_date:null,
        issued_place:null
      },
      options: {
				format: 'DD-MM-YYYY hh:mm:ss',
				useCurrent: true,
				showClear: true,
        showClose: true
      },
      options1: {
				format: 'DD-MM-YYYY',
				useCurrent: false,
				showClear: true,
        showClose: true
			}
    }
  },
  validations: {
    client: {
      name: {
        required,
        maxLength:maxLength(50)
      },
      gender:{
        required
      },
      email:{
        email,
        maxLength:maxLength(50)
      },
      phone: {
        required: requiredIf(function () {
          return (this.business==Business.pawn || this.business==Business.football|| this.business==Business.oto|| this.business==Business.hostel)
        }),
        numeric,
        minLength: minLength(10),
        maxLength:maxLength(15)
      },
      cmnd: {
        numeric,
        required: requiredIf(function () {
          return (this.business==Business.pawn||this.business==Business.hotel||this.business==Business.hostel)
        }),
        maxLength:maxLength(15),
        async isUnique(value) {
          if (value==null) return true
          if(this.client && this.client.id!=null) return true
          const res = await this.checkIDUnique(value)
          return Boolean(res.data)
        }
      },
      issued_date: {
        required: requiredIf(function () {
          return (this.business==Business.pawn||this.business==Business.hotel||this.business==Business.hostel)
        }),
        maxLength:maxLength(20)
      },
      issued_place: {
        required: requiredIf(function () {
          return (this.business==Business.pawn||this.business==Business.hotel||this.business==Business.hostel)
        }),
        maxLength:maxLength(50)
      }
    }
  },
  computed: {
    ...mapGetters('st_client', ['ls_client','filter_client','page_client','per_page_client','page_count_client']),
    user_login(){
      return this.$store.state.st_authentication.user
    },
    business(){
      let user= this.$store.state.st_authentication.user
      if(user==null) return
      return user.company.business
    },
    fields(){
      let fields=[]

      let user=this.$store.state.st_authentication.user
      if(user==null) return
      if(user.role==Role.Super_Admin){
        fields.push({key: 'company',label:this.$t('common.company'),sortable: true})
      }else if(user.role==Role.Admin){
        fields.push({key: 'branch',label:this.$t('common.company_branch'),sortable: true})
      }

      fields.push({key: 'name',label:this.$t('common.fullname'),sortable: true})
      fields.push({key: 'email',label:this.$t('common.email'),sortable: true})
      fields.push({key: 'phone',label:this.$t('common.phone'),sortable: true})
      fields.push({key: 'gender',label:this.$t('common.gender'),sortable: true})
      fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})
      fields.push({key: 'status',label:this.$t('common.status'),sortable: true})

      if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
        fields.push({key: 'action',label:'',sortable: false})
      }

      return fields
    },
    filter:{
      get: function(){
        return this.$store.state.st_client.filter_client
      },
      set: function(text){
        this.$store.commit('st_client/set_filter',text)
      }
    },
    filteredAndSortedData:{
      get: function(){
        let vm=this
        let result = vm.$store.state.st_client.ls_client
        if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
        }

        return result
      }
    }
  },
  methods: {
    ...mapActions('st_client', ['get_ls_client','apply_filter_client','change_page_client']),
    clear(){
      this.client={id:null,gender:null,logo:null,logo_url:null,
      des:null,address:null,phone:null,
      company_id:null,branch_id:null,user_create_id:null,user_update_id:null,
      email:null,code:moment().valueOf().toString(),issued_date:null,
      issued_place:null,business:this.user_login.company.business}

      this.$refs.txt_name.$el.focus()
      this.$v.$reset()
    },
    async checkIDUnique(cmnd){
      debugger
      return await this.$store.dispatch(`st_client/cmnd_unique`,{cmnd:cmnd,company_id:this.user_login.company_id,branch_id:this.user_login.branch_id})
    },
    rowDeleted(item, type){
      if (!item || type !== 'row') return
      if (item.status === -1) return 'table-danger'
    },
    async checkCmndUnique(cmnd){
      return await this.$store.dispatch(`st_client/cmnd_unique`,{cmnd:cmnd,company_id:this.user_login.company_id})
    },
    async recoverObj(obj){
      let app=this
      if(obj.status>=0) return
      let co=await this.$store.dispatch('st_client/recover_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_client()
        app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(co.data.message,app.$t("common.message"))
      }
    },
    confirmDelete(obj) {
      this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        debugger
        if(value!=true) return
        this.deleteObj(obj)
      })
      .catch(err => {
        debugger
        this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
      })
    },
    async deleteObj(obj) {
      debugger
      let app=this
      if(obj.status<0) return
      if(obj.status==1){
        app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.user")}),app.$t("common.message"))
        return
      }
      debugger
      let co=await app.$store.dispatch('st_client/delete_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_client()
        app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(co.data.message,app.$t("common.message"))
      }
    },
    _validations () {
      return new Promise(resolve => {
        if (this.$v.$error || !this.$v.$pending) {
            return resolve()
        }
        let poll = setInterval(() => {
        if (!this.$v.$pending) {
            clearInterval(poll)
            resolve()
        }
        }, 200)
      })
    },
    async isValid () {
        this.$v.$reset()
        this.$v.$touch()
        await this._validations()
        return Promise.resolve(!this.$v.$error)
    },
    async saveClient() {
      const isValid = await this.isValid()
      debugger
      if (!isValid) {
        return
      }
      debugger
      var app = this
      if (app.processing === true) {
        return
      }

      app.processing = true

      debugger
      if(app.client.id){
        app.client.user_update_id=app.user_login.id

        app.$store.dispatch('st_client/update',app.client)
        .then(function (resp) {
          app.get_ls_client()
          app.clear()
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }else{
        app.client.user_create_id=app.user_login.id
        app.client.business=app.user_login.company.business
        app.client.company_id=app.user_login.company_id
        app.client.branch_id=app.user_login.branch_id

        app.$store.dispatch('st_client/create',app.client)
        .then(function (resp) {
          app.get_ls_client()
          app.clear()
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }
    },
    getRowCount (items) {
      return items.length
    },
    rowClicked (item) {
      if(!item) return
      this.client=item
    },
    searchLike:function(item){
      debugger
      return item.name.includes(this.filter)
      ||item.address&&item.address.includes(this.filter)
      ||item.des&&item.des.includes(this.filter)
      ||item.email&&item.email.includes(this.filter)
      ||item.cmnd&&item.cmnd.includes(this.filter)
      ||item.phone&&item.phone.includes(this.filter)
      ||item.gender&&item.gender.includes(this.filter)
    },
    getImage:function(e){
      let app=this
      let image=e.target.files[0]
      if(image.size>102400){
        app.$bvModal.show("viewModal")
        document.getElementById("fileControl").value = ""
        return
      }
      let reader=new FileReader()
      reader.readAsDataURL(image)
      reader.onload=e=>{
        app.client.logo=e.target.result
      }
    }
  },
  mounted () {
    let vm=this
    vm.ls_gender=vm.option_gender
    vm.$refs.txt_name.$el.focus()
    vm.get_ls_client()
  }
}
</script>
